*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.semicolon-network-landing-page {
    background-color: white;
    width: 100%;
    overflow: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: #ff2e2e;
    padding: 0 6.8rem 0 4.8rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

.header-left {
    width: 12rem;
    margin-left: 3.3rem;
}

.header-left img {
    width: 100%;
}

.header-right {
  display: flex;
}

.vision-button,
.your-role-button {
  margin-right: 20px;
  padding: 10px 20px;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 16px;
  background-color: transparent;
}

.why-it-matters-button {
  margin-right: 20px;
  padding: 10px 20px;
  color: #ff2e2e;
  border: none;
  cursor: pointer;
  font-size: 16px;
  background-color: transparent;
}

.join-the-team-button {
    margin-right: 20px;
    padding: 10px 20px;
    background-color: #ff2e2e;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}

@media (min-width: 280px) and (max-width: 320px) {
    .header {
        padding-left: 0.2rem;
        padding-right: 0.1rem;
    }

    .header-left {
        width: 4rem;
        margin-left: 0.1rem;
    }

    .semicolon {
        height: 2rem;
    }

    .join-the-team-button {
        background-color: white;
        color: red;
        padding-right: 0.4rem;
        padding-left: 0.4rem;
        font-size: 0.6rem;
    }

    .vision-button,
    .why-it-matters-button,
    .your-role-button {
      display: none;
    }

    .join-the-team-button {
        background-color: white;
        color: red;
        margin-right: 0.1rem;
        padding-right: 0.4rem;
        font-size: 0.6rem;
      }
}

@media (min-width: 321px) and (max-width: 480px ) {
    .header {
        padding-left: 0.4rem;
        padding-right: 0.1rem;
    }

    .header-left {
        width: 6rem;
        margin-left: 0.5rem;
    }

    .semicolon {
        fill: black;
    }

  .vision-button,
  .why-it-matters-button,
  .your-role-button {
    display: none;
  }

  .join-the-team-button {
    background-color: white;
    color: red;
    margin-right: 0.1rem;
    padding-right: 0.4rem;
    font-size: 0.8rem;
  }
}

@media (min-width: 481px) and (max-width: 768px ) {
    .header {
        padding-left: 0.4rem;
        padding-right: 0.1rem;
    }

    .header-left {
        width: 6rem;
        margin-left: 0.5rem;
    }

  .join-the-team-button {
    background-color: red;
    color: white;
    margin-right: 0.5rem;
    padding: 0.4rem 1rem;
    font-size: 0.8rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px ) {
    .header {
        padding-left: 0.4rem;
        padding-right: 0.1rem;
    }

    .header-left {
        width: 6rem;
        margin-left: 0.5rem;
    }

  .join-the-team-button {
    background-color: red;
    color: white;
    margin-right: 0.5rem;
    padding: 0.4rem 1rem;
    font-size: 0.8rem;
  }
}


@media (min-width: 280px) and (max-width: 320px){
    .header{
        padding-left: 0.2rem;
        padding-right: 0.1rem;
    }
    .header-left{
        width: 4rem;
        margin-left: 0.4rem;
    }
    .semicolon{
        height: 2rem;
    }

    .vision-button,
    .why-it-matters-button,
    .your-role-button{
      display: none;
    }
    .join-the-team-button{
        background-color: white;
        color: red;
        margin-right: 0.1rem;
        padding-right: 0.4rem;
        font-size: 0.6rem;
    }

}


@media (min-width: 321px) and (max-width: 480px ) {

  .header{
      padding-left: 0.4rem;
      padding-right: 0.1rem;
  }

  .header-left{
      width: 6rem;
      margin-left: 0.5rem;
  }

  .vision-button,
  .why-it-matters-button,
  .your-role-button{
    display: none;
  }

  .join-the-team-button{
    background-color: white;
    color: red;
    margin-right: 0.1rem;
    padding-right: 0.4rem;
    font-size: 0.6rem;
  }
}



@media (min-width: 481px) and (max-width: 768px ) {
  .header{
      padding-left: 0.4rem;
      padding-right: 0.1rem;
  }

  .header-left{
      width: 6rem;
      margin-left: 0.5rem;
  }

.join-the-team-button{
  background-color: red;
  color: white;
  margin-right: 0.5rem;
  padding: 0.4rem 1rem;
  font-size: 0.8rem;
}
}


@media (min-width: 769px) and (max-width: 1024px ) {
  .header{
      padding-left: 0.4rem;
      padding-right: 0.1rem;
  }

  .header-left{
      width: 6rem;
      margin-left: 0.5rem;
  }

.join-the-team-button{
  background-color: red;
  color: white;
  margin-right: 0.5rem;
  padding: 0.4rem 1rem;
  font-size: 0.8rem;
}
}


.semicolon-community {
    position: relative;
}



.image-scroll-container {
    background: rgba(0,0,0,0.6)url(../../../assets/images/semicolon-image3.jpg);
    border: 1px solid grey;
    background-blend-mode: darken;
    background-size: cover;
    height: 52rem;
}


.community-header,
.community-text,
.join-the-team-community-button {
  position: absolute;
  color: white;
}

.community-text{
  width: 32rem;
}

.join-the-team-community-button{
  font-weight: bold;
}

.community-text {
    width: 32rem;
}

.join-the-team-community-button {
    font-weight: bold;
}

.community-header {
    top: 180px;
    left: 110px;
    font-size: 57px;
    font-weight: 700;
    margin-left: 20px;
    font-family: Roboto Condensed, sans-serif;
    line-height: 74px;
    text-align: left;
    width: 40%;
}

.community-text {
    top: 200px;
    left: 110px;
    margin-top: 140px;
    margin-left: 20px;
    font-family: Poppins, serif;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
}

.content{
    top: 390px;
    font-size: 16px;
}

.join-the-team-community-button {
    width: 303px;
    height: 68px;
    top: 600px;
    left: 130px;
    background-color: #ff2e2e;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 24px;
    transition: all 0.5s;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
}

.join-the-team-community-button {
  display: inline-block;
  transition: 0.5s;
}

.join-the-team-community-button:after {
  content: '   »';
  opacity: 0;
  top: 14px;
  right: -20px;
  transition: 0.5s;
}

.join-the-team-community-button:hover:after {
  opacity: 1;
  right: 10px;
}


@media (min-width: 280px) and (max-width: 320px){
    .community-header {
        top: 100px;
        left: 15px;
        font-size: 24px;
        text-align: center;
        line-height: 32px;
        width: 80%;
    }

    .community-text {
        top: 180px;
        left: 15px;
        font-size: 12px;
        line-height: 20px;
        padding: 0.2rem;
        margin: 1rem;
        width: 80%;
        text-align: center;
    }
    .content{
        top: 300px;
        font-size: 11px;
    }
    .join-the-team-community-button {
        width: 100px;
        height: 30px;
        top: 370px;
        left: 110px;
        font-size: 12px;
        text-align: center;
        padding-left: 0.6rem;
    }

    .image-scroll-container{
        background: rgba(0,0,0,0.6)url(../../../assets/images/mobile-background.jpg);
        background-size:cover;
        height: 30rem;
    }

}

@media (min-width: 321px ) and (max-width: 389px ) {
    .semicolon-community{
        margin: 0.5rem 0 1rem 0;
    }

    .community-header {
        top: 160px;
        left: 5px;
        font-size: 36px;
        font-weight: 700;
        line-height: 44px;
        text-align: center;
        width: 85%;
    }

    .community-text {
        top: 120px;
        left: 15px;
        margin-top: 150px;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        width: 80%;
    }

    .content {
        top: 300px;
        font-size: 11px;
    }

    .join-the-team-community-button {
        width: 130px;
        height: 40px;
        top: 500px;
        left: 120px;
        padding-bottom: 0.3rem;
        font-size: 12px;
        text-align: center;
        margin-top: 1rem;
    }

    .image-scroll-container {
        background: rgba(0,0,0,0.6)url(../../../assets/images/mobile-background.jpg);
        background-size:cover;
        height: 40rem;
    }
}

@media (min-width: 390px) and (max-width: 480px ) {
    .semicolon-community {
        margin: 0.5rem 0 1rem
    }

    .community-header {
        top: 160px;
        left: 20px;
        font-size: 36px;
        font-weight: 700;
        line-height: 44px;
        text-align: center;
        width: 80%;
    }

    .community-text {
        top: 120px;
        left: 30px;
        margin-top: 150px;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        width: 80%;
    }

    .content {
        top: 360px;
        font-size: 11px;
    }

    .join-the-team-community-button {
        width: 130px;
        height: 40px;
        top: 550px;
        left: 140px;
        font-size: 12px;
        text-align: center;
    }

    .image-scroll-container {
        background: rgba(0,0,0,0.6)url(../../../assets/images/mobile-background.jpg);
        background-size:cover;
        height: 45rem;
    }
}

@media (min-width: 481px ) and (max-width: 767px ) {
    .semicolon-community {
        margin: 0.5rem 0 1rem 0;
    }

    .community-header {
        top: 160px;
        left: 40px;
        font-size: 45px;
        text-align: center;
        line-height: 52px;
        width: 80%;
    }

    .community-text {
        top: 160px;
        left: 30px;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        width: 80%;
    }

    .content{
        top: 330px;
        font-size: 11px;
    }

    .join-the-team-community-button {
        width: 150px;
        height: 40px;
        top: 530px;
        left: 180px;
        font-size: 16px;
        text-align: center;
    }

    .image-scroll-container{
        background: rgba(0,0,0,0.6)url(../../../assets/images/mobile-background.jpg);
        background-size:cover;
    }
}


@media (min-width: 768px ) and (max-width: 859px) {
    .semicolon-community{
        margin: 0.5rem 0 1rem 0;
    }

    .community-header {
        top: 200px;
        left: 190px;
        font-size: 45px;
        text-align: center;
        line-height: 52px;
        width: 55%;
    }

    .community-text {
        font-size: 18px;
        line-height: 28px;
        width: 70%;
        text-align: center;
        margin-left: 1.5rem;
    }
    .content{
        top: 360px;
        font-size: 11px;
    }

    .join-the-team-community-button {
        width: 250px;
        height: 50px;
        top: 560px;
        left: 300px;
        font-size: 16px;
        text-align: center;
    }
}

@media (min-width: 860px ) and (max-width: 1024px) {
    .semicolon-community{
        margin: 0.5rem 0 1rem 0;
    }

    .community-header {
        top: 200px;
        left: 190px;
        font-size: 45px;
        text-align: center;
        line-height: 52px;
        width: 55%;
    }

    .community-text {
        font-size: 18px;
        line-height: 28px;
        width: 70%;
        text-align: center;
        margin-left: 1.5rem;
    }
    .content{
        top: 360px;
        font-size: 11px;
    }

    .join-the-team-community-button {
        width: 250px;
        height: 50px;
        top: 560px;
        left: 370px;
        font-size: 16px;
        text-align: center;
    }
}

@media (min-width: 1025px ) and (max-width: 1216px) {
    .community-header {
        top: 200px;
        left: 190px;
        font-size: 45px;
        text-align: center;
        line-height: 52px;
        width: 55%;
    }

    .community-text {
        font-size: 18px;
        line-height: 28px;
        width: 70%;
        text-align: center;
        margin-left: 1.5rem;
    }
    .join-the-team-community-button {
        width: 250px;
        height: 50px;
        top: 590px;
        left: 400px;
        font-size: 16px;
        text-align: center;
    }
}




.what-we-envision {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 100px;
}

.what-we-envision-header {
  margin-bottom: 50px;
  font-family: Roboto Condensed, sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 42px;
  text-align: left;
  color: #FF2E2E;
}

.what-we-envision-containers {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    justify-content:center;
    align-items: center;
}

.what-we-envision-container {
    box-shadow: 0 4px 4px 0 #00000040;
    width: 250px;
    height: 349px;
    top: 957px;
    left: 206px;
    border-radius: 5px;
    margin-right: 40px;
    padding: 2rem;
}

.what-we-envision-red-container {
    color: white;
    background: linear-gradient(0deg, #FF2E2E, #FF2E2E);
    box-shadow: 0 4px 4px 0 #00000040;
    width: 250px;
    height: 349px;
    top: 957px;
    left: 206px;
    border-radius: 5px;
    margin-right: 40px;
    padding: 2rem;
}

.what-we-envision-sub-header {
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: bolder;
    line-height: 24px;
    text-align: left;
}

.what-we-envision-image {
    margin-left: 150px;
    margin-top: -300px;
    width: 3rem;
}

.what-we-envision-text {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
}

.what-we-envision-text-2 {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    margin-top: -20px;
}

@media screen and (max-width: 426px ) {
    .what-we-envision-header {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .what-we-envision-containers {
        margin-left: 5rem;
    }

    .what-we-envision-container {
        margin-bottom: 2rem;
    }

    .what-we-envision-red-container {
        margin-bottom: 2rem;
    }
}

@media (min-width: 280px) and (max-width: 320px) {
    .what-we-envision-header {
        font-size: 14px;
        margin-bottom: 14px;
    }

    .what-we-envision-containers {
        margin-left: 36px;
    }

    .what-we-envision-container {
        width: 220px;
        height: 300px;
        margin-right: 40px;
        padding: 1.5rem;
    }

    .what-we-envision-red-container {
        width: 220px;
        height: 300px;
        padding: 1.5rem;
    }

    .what-we-envision-sub-header {
        font-size: 14px;
        font-weight: bolder;
        line-height: 20px;
        text-align: left;
    }

    .what-we-envision-image {
        width: 2.5rem;
        margin-left: 120px;
        margin-top: -300px;
    }

    .what-we-envision-text {
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
    }

    .what-we-envision-text-2 {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
        margin-bottom: 1.5rem;
        margin-top: -20px;
    }
}

@media (min-width: 321px) and (max-width: 480px) {
    .join-the-team-button {
        background-color: white;
        color: red;
        padding-right: 0.4rem;
        padding-left: 0.4rem;
        font-size: 0.8rem;
    }

    .what-we-envision-header {
        font-size: 14px;
        margin-bottom: 14px;
    }

    .what-we-envision-containers {
        margin-left: 46px;
    }

    .what-we-envision-container {
        width: 220px;
        height: 300px;
        margin-right: 40px;
        padding: 1.5rem;
    }
    .what-we-envision-red-container {
        width: 220px;
        height: 300px;
        padding: 1.5rem;
    }

    .what-we-envision-sub-header {
        font-size: 14px;
        font-weight: bolder;
        line-height: 20px;
        text-align: left;
    }

    .what-we-envision-image {
        width: 2.5rem;
        margin-left: 120px;
        margin-top: -300px;
    }

    .what-we-envision-text {
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
    }

    .what-we-envision-text-2 {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
        margin-bottom: 1.5rem;
        margin-top: -20px;
    }
}

@media (min-width: 481px) and (max-width: 768px ) {
    .join-the-team-button {
        background-color: white;
        color: red;
        padding-right: 0.4rem;
        padding-left: 0.4rem;
        font-size: 0.8rem;
    }

    .what-we-envision-header {
        font-size: 18px;
        margin-left: 2rem;
        margin-bottom: 24px;
    }

    .what-we-envision-containers {
        margin-left: 86px;
    }

    .what-we-envision-container {
        width: 220px;
        height: 300px;
        margin-right: 40px;
        margin-bottom: 2rem;
        padding: 1.5rem;
    }
    .what-we-envision-red-container {
        width: 220px;
        height: 300px;
        padding: 1.5rem;
        margin-bottom: 2rem;
    }

    .what-we-envision-sub-header {
        font-size: 14px;
        font-weight: bolder;
        line-height: 20px;
        text-align: left;
    }

    .what-we-envision-image {
        width: 2.5rem;
        margin-left: 120px;
        margin-top: -300px;
    }

    .what-we-envision-text {
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
    }

    .what-we-envision-text-2 {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
        margin-bottom: 1.5rem;
        margin-top: -20px;
    }
}

@media (min-width: 769px) and (max-width: 1024px ) {
    .what-we-envision-container {
        margin-bottom: 2rem;
        padding: 1.5rem;
    }

    .what-we-envision-red-container {
        margin-bottom: 2rem;
        padding: 1.5rem;
    }
}

.why-it-matters {
  margin-top: 50px;
  margin-bottom: 50px;
}

.why-it-matters-header {
  font-family: Roboto Condensed, sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 42px;
  color: #ff2e2e;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
}


.why-it-matters-text {
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: lighter;
    line-height: 27px;
    letter-spacing: 0.07em;
    text-align: center;
    padding: 0 16rem;
    margin: 0 5rem;
}

@media (min-width: 250px) and (max-width: 480px ) {
    .why-it-matters-header {
        font-size: 16px;
        margin-bottom: 15px;
        margin-left: 15px;
        text-align: center;
    }

    .why-it-matters-text {
        text-align: center;
        padding: 1rem 1rem;
        font-size: 12px;
        line-height: 20px;
    }
}

@media (min-width: 481px) and (max-width: 768px ) {
    .why-it-matters-header {
        font-size: 18px;
        margin-bottom: 15px;
        margin-left: 15px;
        text-align: center;
    }

    .why-it-matters-text {
        text-align: center;
        padding: 1rem 1rem;
        font-size: 12px;
        line-height: 20px;
    }
}

@media (min-width: 769px) and (max-width: 1024px ) {
    .why-it-matters-header {
        font-size: 24px;
        margin-bottom: 15px;
        margin-left: 15px;
        text-align: center;
    }

    .why-it-matters-text {
        text-align: center;
        padding: 1rem 1rem;
        font-size: 16px;
        line-height: 24px;
    }
}

@media (min-width: 250px) and (max-width: 480px ) {
  .why-it-matters-header{
      font-size: 16px;
      margin-bottom: 15px;
      margin-left: 15px;
      text-align: center;
  }
  .why-it-matters-text{
      text-align: center;
      padding: 1rem 1rem;
      font-size: 12px;
      line-height: 20px;
  }
}

@media (min-width: 481px) and (max-width: 768px ) {
  .why-it-matters-header{
      font-size: 18px;
      margin-bottom: 15px;
      margin-left: 15px;
      text-align: center;
  }
  .why-it-matters-text{
      text-align: center;
      padding: 1rem 1rem;
      font-size: 12px;
      line-height: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1024px ) {
  .why-it-matters-header{
      font-size: 24px;
      margin-bottom: 15px;
      margin-left: 15px;
      text-align: center;
  }
  .why-it-matters-text{
      text-align: center;
      padding: 1rem 1rem;
      font-size: 16px;
      line-height: 24px;
      padding: 0 8rem;
  }
}




.your-role {
    display: flex;
    justify-content: space-evenly;
    padding: 5rem 15rem;
    flex-wrap: wrap;
}



.your-role-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30rem;
}

.your-role-header {
  font-family: Roboto Condensed, sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 47px;
  text-align: left;
  color: #ff2e2e;
}

.your-role-text {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.07em;
  text-align: left;
}

.your-role-register-text {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: left;
    color: #02012AD9;
}

.your-role-join-the-team-button {
    padding: 10px 20px;
    background-color: #ff2e2e;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    width: 222px;
    height: 58px;
}

.your-role-right {
    width: 30rem;
    height: 25rem;
}

.img-header {
    display: none;
}

@media (min-width: 280px) and (max-width: 480px ) {
    .your-role-header {
        text-align: center;
        font-size: 1.5rem;
    }

    .your-role {
        padding:2rem 5rem 2rem 5rem;
    }

    .your-role-text {
        text-align: center;
        margin-top: 0.5rem;
        padding: 0.2rem;
        font-size: 12px;
    }

    .your-role-register-text {
        text-align: center;
        margin-top: 0.5rem;
        font-size: 14px;
    }

    .your-role-right {
        display: none;
    }

    .your-role-join-the-team-button {
     display: none;
    }
}

@media (min-width: 481px) and (max-width: 768px ) {
    .your-role-header {
        font-size: 20px;
        margin-bottom: 15px;
        margin-left: 15px;
        text-align: center;
    }

    .your-role-text {
        text-align: center;
        padding: 1rem 1rem;
        font-size: 16px;
        line-height: 20px;
    }

    .your-role-register-text {
        text-align: center;
        margin-top: 0.5rem;
        font-size: 14px;
    }
    .your-role-right {
        display: none;
    }

    .your-role-join-the-team-button {
        display: none;
    }
}

@media (min-width:769px) and (max-width: 1024px ) {
    .your-role-header {
        font-size: 26px;
        margin-bottom: 15px;
        margin-left: 15px;
        text-align: center;
    }

    .your-role-text {
        text-align: center;
        padding: 1rem 1rem;
        font-size: 16px;
        line-height: 20px;
    }

    .your-role-register-text {
        text-align: center;
        margin-top: 0.5rem;
        font-size: 14px;
    }

    .your-role-right {
        display: none;
    }

    .your-role-join-the-team-button {
        display: none;
    }
}

@media (min-width:1025px) and (max-width: 1200px ) {
    .your-role-header {
        font-size: 45px;
        margin-bottom: 15px;
        margin-left: 15px;
        text-align: center;
    }

    .your-role-text {
        text-align: center;
        padding: 1rem 1rem;
        font-size: 18px;
        line-height: 20px;
    }

    .your-role-register-text {
        text-align: center;
        margin-top: 0.5rem;
        font-size: 18px;
    }

    .your-role-right {
        display: none;
    }

    .your-role-join-the-team-button {
        display: none;
    }
}

@media (min-width:1201px) and (max-width: 1440px) {
    .your-role {
        display: flex;
        justify-content: space-evenly;
        padding: 5rem 7rem;
        flex-wrap: wrap;
    }

    .your-role-header {
        font-size: 45px;
        margin-bottom: 15px;
        margin-left: 15px;
        text-align: left;
    }

    .your-role-text {
        text-align: left;
        padding: 0 0 0 0;
        font-size: 18px;
        line-height: 25px;
    }

    .img-header {
        font-size: 2rem;
    }

    .your-role-register-text {
        text-align: left;
        margin-top: 0.5rem;
        font-size: 18px;
    }

    .your-role-join-the-team-button {
        padding: 10px 20px;
        background-color: #ff2e2e;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 20px;
        font-weight: bold;
        width: 222px;
        height: 58px;
    }
}

.your-role-right img {
    width: 100%;
    height: 100%;
}

.join-the-team {
    justify-content: center;
    align-items: center;
    padding: 0 20rem;
}

.join-the-team-header {
  text-align: center;
  margin-top: 50px;
  font-family: Roboto Condensed, sans-serif;
  font-size: 40px;
  line-height: 47px;
  color: #ff2e2e;
  font-weight: bold;
  margin-bottom: 50px;
}

.registration-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    padding: 4rem 0 4rem 0;
    margin-bottom: 4rem;
    box-shadow: 0 4px 4px 0 #00000040;
}

#fullName,
#email,
#cohort,
#ancestorOrNative,
#employmentStatus,
#proficientLanguages {
    flex: 1;
    margin-right: 10px;
    width: 350px;
    height: 50px;
    top: 2584px;
    left: 306px;
    border: 1px solid lightgrey;
    margin-bottom: 30px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 275;
    line-height: 24px;
    text-align: left;
    color: black;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
}

input::placeholder {
    color: lightgrey;
}

select > option {
    color: grey;
}

.phone-stack {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45rem;
    margin-bottom: 2rem;
}

.phone {
    display: flex;
    align-items: center;
    border: 1px solid lightgrey;
    width: 730px;
    height: 50px;
    margin-right: 0.6rem;
    border-radius: 5px;
}

#stack {
    width: 730px;
    height: 50px;
    border-radius: 5px;
    margin-right: 0.7rem;
    padding-left: 0.5rem;
    color: grey;
}

#fullName:hover,
#email:hover,
#stack:hover,
#cohort:hover,
#ancestorOrNative:hover,
#employmentStatus:hover,
#proficientLanguages:hover {
    background-color: #ffedef;
    color: grey;
}

.join-the-team-submit-button {
    width: 222px;
    height: 58px;
    background-color: #ff2e2e;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    color: white;
    gap: 20px;
    margin-top: 20px;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-weight: bold;
    text-align: center;
}

.join-the-team-submit-button:hover {
  background-color: #ff5555;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
}

@media (min-width: 270px) and (max-width: 280px ) {
    .join-the-team-header {
        text-align: center;
        margin-top: 50px;
        font-size: 18px;
        line-height: 7px;
        margin-bottom: 20px;
    }

    .join-the-team {
        padding: 0 0.2rem;
    }

    #fullName,
    #email,
    #cohort,
    #ancestorOrNative,
    #employmentStatus,
    #proficientLanguages {
        flex: 1;
        margin-left: 10px;
        width: 250px;
        height: 40px;
        font-size: 14px;
    }

    .phone-stack {
        display: flex;
        flex-wrap: wrap;
        width: 19.5rem;
        margin-bottom: 2rem;
    }

    .phone {
        margin-left: 1.9rem;
        border: none;
    }

    #stack {
        display: flex;
        width: 380px;
        height: 40px;
        border-radius: 5px;
        margin-top: 2rem;
        margin-right: 1.8rem;
        margin-left: 1.8rem;
        padding-left: 0.5rem;
    }

    .join-the-team-submit-button {
        width: 100px;
        height: 38px;
        font-size: 1.2rem;
        text-align: center;
        margin-left: 0.8rem;
    }
}

@media (min-width: 281px) and (max-width: 320px ) {
    .join-the-team-header {
        text-align: center;
        margin-top: 50px;
        font-size: 18px;
        line-height: 7px;
        margin-bottom: 20px;
    }

    .join-the-team {
        padding: 0 0.2rem;
    }

    #fullName,
    #email,
    #cohort,
    #ancestorOrNative,
    #employmentStatus,
    #proficientLanguages {
        flex: 1;
        margin-left: 10px;
        width: 290px;
        height: 40px;
        font-size: 14px;
    }

    .phone-stack {
        display: flex;
        flex-wrap: wrap;
        width: 22rem;
        margin-bottom: 2rem;
    }

    .phone {
        margin-left: 1.5rem;
        border: none;
    }

    #stack {
        display: flex;
        width: 380px;
        height: 40px;
        border-radius: 5px;
        margin-top: 2rem;
        margin-right: 1.8rem;
        margin-left: 1.8rem;
        padding-left: 0.5rem;
    }

    .join-the-team-submit-button {
        width: 140px;
        height: 48px;
        font-size: 1.2rem;
        text-align: center;
        margin-left: -2rem;
    }
}

@media (min-width: 321px) and (max-width: 375px ) {
    .join-the-team-header {
        text-align: center;
        margin-top: 50px;
        font-size: 18px;
        line-height: 7px;
        margin-bottom: 20px;
    }

    .join-the-team {
        padding: 0 0.2rem;
    }

    #fullName,
    #email,
    #cohort,
    #ancestorOrNative,
   
    #proficientLanguages {
        flex: 1;
        margin-left: 30px;
        width: 300px;
        height: 40px;
        font-size: 14px;
    }

    .phone-stack {
        display: flex;
        flex-wrap: wrap;
        width: 22rem;
        margin-bottom: 2rem;
    }

    .phone {
        margin-left: 1.8rem;
        border: none;
    }

    #stack {
        display: flex;
        width: 380px;
        height: 40px;
        border-radius: 5px;
        margin-top: 2rem;
        margin-right: 1.4rem;
        margin-left: 1.8rem;
        padding-left: 0.5rem;
    }

    #employmentStatus{
        flex: 1;
        margin-left: 20px;
        width: 300px;
        height: 40px;
        font-size: 14px;
    }

    .join-the-team-submit-button {
        width: 140px;
        height: 48px;
        font-size: 1.2rem;
        text-align: center;
        margin-left: 1rem;
    }
}

@media (min-width: 376px) and (max-width: 411px ) {
    .join-the-team-header {
        text-align: center;
        margin-top: 50px;
        font-size: 18px;
        line-height: 7px;
        margin-bottom: 20px;
    }

    .join-the-team {
        padding: 0 0.5rem;
    }

    .registration-form {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid grey;
        padding: 4rem 1.5rem 4rem 2rem;
        margin-bottom: 4rem;
        box-shadow: 0 4px 4px 0 #00000040;
    }

    .phone-stack {
        display: flex;
        flex-wrap: wrap;
        width: 23rem;
        margin-bottom: 2rem;
    }

    .phone {
        margin-left: -0.2rem;
        border: none;
    }

    #stack {
        display: flex;
        width: 300px;
        height: 40px;
        border-radius: 5px;
        margin-top: 2rem;
        margin-right: 1.9rem;
        margin-left: -0.2rem;
        padding-left: 0.5rem;
    }

    #fullName,
    #email,
    #cohort,
    #ancestorOrNative,
    #proficientLanguages {
        flex: 1;
        margin-left: -30px;
        width: 300px;
        height: 40px;
        font-size: 14px;
    }

    #employmentStatus{
        margin-left: -65px;
        width: 300px;
        height: 40px;
    }

    .join-the-team-submit-button {
        width: 140px;
        height: 48px;
        font-size: 1.2rem;
        text-align: center;
        margin-left: -4rem;
    }
}

@media (min-width: 412px) and (max-width: 420px ) {
    .join-the-team-header {
        text-align: center;
        margin-top: 50px;
        font-size: 18px;
        line-height: 7px;
        margin-bottom: 20px;
    }

    .join-the-team {
        padding: 0 1.5rem;
    }

    .registration-form {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid grey;
        padding: 4rem 1rem 4rem 1.5rem;
        margin-bottom: 4rem;
        box-shadow: 0 4px 4px 0 #00000040;
    }

    .phone-stack {
        display: flex;
        flex-wrap: wrap;
        width: 23rem;
        margin-bottom: 2rem;
    }

    .phone {
        margin-left: 0.5rem;
        border: none;
    }

    #stack {
        display: flex;
        width: 300px;
        height: 40px;
        border-radius: 5px;
        margin-top: 2rem;
        margin-right: 0.7rem;
        margin-left: 0.5rem;
        padding-left: 0.5rem;
    }

    #fullName,
    #email,
    #cohort,
    #ancestorOrNative,
    
    #proficientLanguages {
        flex: 1;
        margin-left: -15px;
        width: 300px;
        height: 40px;
        font-size: 14px;
    }

    #employmentStatus{
        margin-left: -45px;
        width: 300px;
        height: 40px;
        font-size: 14px;
    }
    .join-the-team-submit-button {
        width: 140px;
        height: 48px;
        font-size: 1.2rem;
        text-align: center;
        margin-left: -2rem;
    }
}

@media (min-width: 421px) and (max-width: 480px ) {
    .join-the-team-header {
        text-align: center;
        margin-top: 50px;
        font-size: 18px;
        line-height: 7px;
        margin-bottom: 20px;
    }

    .join-the-team {
        padding: 0 0.5rem;
    }

    .registration-form {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid grey;
        padding: 4rem 2rem 4rem 4rem;
        margin-bottom: 4rem;
        box-shadow: 0 4px 4px 0 #00000040;
    }

    .phone-stack {
        display: flex;
        flex-wrap: wrap;
        width: 23rem;
        margin-bottom: 2rem;
    }

    .phone {
        border: none;
        margin-left: -0.5rem;
    }

    #stack {
        display: flex;
        width: 300px;
        height: 40px;
        border-radius: 5px;
        margin-top: 2rem;
        margin-right: 0.7rem;
        margin-left: -0.5rem;
        padding-left: 0.5rem;
    }

    #fullName,
    #email,
    #cohort,
    #ancestorOrNative,
    #proficientLanguages {
        flex: 1;
        margin-left: -35px;
        width: 300px;
        height: 40px;
        font-size: 14px;
    }

    #employmentStatus{
        margin-left: -70px;
        width: 300px;
        height: 40px;
        font-size: 14px;
    }

    .join-the-team-submit-button {
        width: 140px;
        height: 48px;
        font-size: 1.2rem;
        text-align: center;
        margin-left: -2rem;
    }
}

@media (min-width:481px) and (max-width: 499px ) {
    .join-the-team-header {
        font-size: 28px;
    }

    .join-the-team {
        padding: 0 2rem;
    }

    .registration-form {
    
        padding: 4rem 2rem;
    }

    .phone {
        margin-bottom: 1rem;
        margin-left: 2.4rem;
        border: none;
    }

    .phone-stack {
        display: flex;
        flex-wrap: wrap;
        width: 23rem;
        margin-bottom: 2rem;
    }

    #stack {
        display: flex;
        width: 300px;
        height: 40px;
        border-radius: 5px;
        margin-top: 0.5rem;
        margin-right: 0.7rem;
        margin-left: 2.4rem;
        padding-left: 0.5rem;
    }

    #fullName,
    #email,
    #cohort,
    #ancestorOrNative,
    
    #proficientLanguages {
        flex: 1;
        margin-left: 30px;
        width: 300px;
        height: 40px;
        font-size: 14px;
    }

    #employmentStatus{
        margin-left: 15px;
        width: 300px;
        height: 40px;
        font-size: 14px;
    }

    .join-the-team-submit-button {
        width: 140px;
        height: 48px;
        font-size: 1.2rem;
        text-align: center;
        margin-left: 1rem;
    }
}

@media (min-width:500px) and (max-width: 767px ) {
    .join-the-team-header {
        font-size: 28px;
    }

    .join-the-team {
        padding: 0 2rem;
    }

    .registration-form {
    
        padding: 4rem 2rem;
    }

    .phone {
        margin-bottom: 1rem;
        margin-left: 2.4rem;
        border: none;
    }

    .phone-stack {
        display: flex;
        flex-wrap: wrap;
        width: 23rem;
        margin-bottom: 2rem;
    }

    #stack {
        display: flex;
        width: 300px;
        height: 40px;
        border-radius: 5px;
        margin-top: 0.5rem;
        margin-right: 0.7rem;
        margin-left: 2.4rem;
        padding-left: 0.5rem;
    }

    #fullName,
    #email,
    #cohort,
    #ancestorOrNative,
    
    #proficientLanguages {
        display: block;
        margin-left: 15px;
        width: 300px;
        height: 40px;
        font-size: 14px;
    }

    #employmentStatus{
        margin-left: 15px;
        width: 300px;
        height: 40px;
        font-size: 14px;
    }

    .join-the-team-submit-button {
        width: 140px;
        height: 48px;
        font-size: 1.2rem;
        text-align: center;
        margin-left: 1rem;
    }
}

@media (min-width:768px) and (max-width: 1024px ) {
    .join-the-team {
        padding: 0 10rem;
    }

    .join-the-team-header {
        font-size: 28px;
    }

    .registration-form {
        padding: 5rem 15rem;
        margin-left: -3rem;
    }

    .phone {
        margin-bottom: 2rem;
    }

    .phone-stack {
        display: flex;
        flex-wrap: wrap;
        width: 23rem;
        margin-bottom: 2rem;
    }
    

    .join-the-team-submit-button {
        width: 140px;
        height: 48px;
        text-align: center;
        margin-left: 1rem;
        font-size: 1.2rem;
    }
}

@media (min-width:1025px) and (max-width: 1360px ) {
    .registration-form {
        padding: 5rem 15rem;
    }

    .phone {
        margin-bottom: 2rem;
    }

    .phone-stack {
        display: flex;
        flex-wrap: wrap;
        width: 23rem;
        margin-bottom: 2rem;
    }

    .join-the-team-submit-button {
        width: 140px;
        height: 48px;
        font-size: 1.2rem;
        text-align: center;
        margin-left: 1rem;
    }
}

footer {
    background-color: #ff2e2e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 5rem 8rem 5rem 8rem;
}

.child-right {
    align-self: flex-end;
}

.business {
    color: white;
    margin: 0.5rem;
}

div p {
    padding: 1rem 0;
}

.elipses {
    color: white;
}

@media (min-width: 280px) and (max-width: 480px ) {
    footer{
        justify-content:flex-start;
        padding: 5rem 4rem 5rem 0.5rem;
    }

    footer div {
        margin-bottom: 2rem;
    }
}

@media (min-width: 481px) and (max-width: 768px ) {
    footer div {
        margin-bottom: 2rem;
    }
}

@media (min-width: 769px) and (max-width: 1024px ) {
    footer div {
        margin-bottom: 2rem;
        margin-right: 1rem;
    }
}